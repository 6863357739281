import { useContentfulAsset } from '@services/contentful/hooks';
import { useIsMobile } from '@utils/hooks';
import { MEDIUM_MAX_WIDTH } from '@constants';
import { Button, Heading, Img, Modal, Paragraph, Spacer } from '@components';
import { InteractiveCardEntrySkeletonType } from '@ts/contentful';
import { NormalizedProduct } from '@ts/product';

import styles from './MarketingCard.module.scss';

type MarketingCardProps = {
	color: string;
	product: NormalizedProduct;
	isAccordian?: boolean;
	showTags?: boolean;
	handleSwatchChange: ({ option, type }: { option: string; type: 'frame' | 'lens' | 'option' }) => void;
	isPlp?: boolean;
};

const MarketingCard = ({
	color,
	product,
	isAccordian = false,
	showTags = true,
	handleSwatchChange,
	isPlp = true,
}: MarketingCardProps) => {
	let fields;
	const isMobile = useIsMobile({ maxWidth: MEDIUM_MAX_WIDTH });
	const { data } = useContentfulAsset<InteractiveCardEntrySkeletonType>({
		id: 'compareMarketingCard',
		query: {
			'content_type': 'plpMarketingBlock',
			'fields.slug': 'compare-our-frames',
		},
	});

	if (!data) {
		return null;
	} else {
		fields = data[0]?.fields;
	}
	const { title, buttonLabel1, buttonColor1, background, subtitle, slug } = fields;

	return (
		<Modal>
			<div className={styles.container} data-marketing-card-compare>
				<Modal.Trigger className={styles.modalTrigger}>
					<div className={styles.bgImage}>
						<Img src={background?.fields?.file?.url} alt={slug} noSrcset />
					</div>
					<div className={styles.content}>
						<div className={styles.description}>
							<Heading tag='h5' removeDefaultMargin className={styles.title}>
								{title}
							</Heading>
							<Paragraph className={styles.subtitle}>{subtitle}</Paragraph>
						</div>
						<Spacer size={'0.8rem'} />
						<Button
							color={buttonColor1}
							fullWidth={!isMobile}
							label={isMobile ? 'Compare' : buttonLabel1}
							linkStyle={isMobile}
							size={isMobile ? 'xsmall' : 'medium'}
						/>
					</div>
				</Modal.Trigger>
			</div>
			<Modal.Content className={styles.modal}>
				<Heading tag='h5' removeDefaultMargin className={styles.title}>
					{title}
				</Heading>
				<Paragraph className={styles.modalSubtitle}>{subtitle}</Paragraph>
				{/* <Compare
					color={color}
					product={product}
					isAccordian={isAccordian}
					showTags={showTags}
					callback={handleSwatchChange}
					isPlp={isPlp}
				/> */}
			</Modal.Content>
		</Modal>
	);
};

export default MarketingCard;
